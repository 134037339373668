import { Component, Input } from "@angular/core";

import { CoreMenuItem } from "@core/types";
import { CoreMenuService } from "../../core-menu.service";
import { AuthenticationService } from "app/auth/service";
import { Role } from "app/auth/models";

@Component({
  selector: "[core-menu-vertical-item]",
  templateUrl: "./item.component.html",
})
export class CoreMenuVerticalItemComponent {
  @Input() item: CoreMenuItem;

  activeUserRole?: String;
  Role: Role;

  constructor(
    private _authenticationService: AuthenticationService,
    private _coreMenuService: CoreMenuService
  ) {}

  get role() {
    return this._coreMenuService.currentUser?.role;
  }

  ngOnInit() {
    // this.activeUserRole = this._authenticationService.currentUserValue.role;
    if (this._authenticationService.currentUserValue !== null) {
      this.activeUserRole = this._authenticationService.currentUserValue.role;
    } else {
      // Handle the case where currentUserValue is null
      return null; // or some default value or appropriate error handling
    }
  }
}
