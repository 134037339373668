import { Component, HostBinding, Input } from '@angular/core';
import { CoreMenuService } from '../../core-menu.service';

@Component({
  selector: '[core-menu-horizontal-item]',
  templateUrl: './item.component.html'
})
export class CoreMenuHorizontalItemComponent {
  @Input()
  item: any;

    constructor(private _coreMenuService: CoreMenuService) { }

    get role() {
        return this._coreMenuService.currentUser?.role;
    }
}

