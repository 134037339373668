import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, of } from "rxjs";
import { io } from "socket.io-client";
import { tap } from "rxjs/operators";

export interface Staffs {
  success: boolean;
  totalResults: number;
  staffs: Staff[];
}

export interface Staff {
  id: number;
  referenceKey: string;
  cognitoReferenceKey: string;
  userName: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isActive: number;
  password?: string;
  acceptTerms: number;
  avatar: string;
  role: string;
  lastLogin: string;
  created: string;
  updated?: string;
  verified: any;
  verificationToken: any;
  resetToken: any;
  resetTokenExpires: any;
  passwordReset: any;
  createdById: any;
  organizationId: number;
  departmentId: any;
  organizationName: string;
  openDischarges: number;
  completedDischarges: number;
  totalDischarges: number;
  pendingTodos: number;
  totalFlags: number;
}

export interface Details {
  success: boolean;
  openCallBackCount: number;
  openFlagThrownCount: number;
  completedSpokeToPatientCount: number;
  completedWrongNumberCount: number;
  completedMaxAttemptsCount: number;
  otherCount: number;
}

export interface Preferences {
  success: boolean;
  preferences: Preference[];
}

export interface Preference {
  Id: number;
  UserId: number;
  NotificationType: string;
  IsEnabled: number;
}

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private _http: HttpClient) {}

  setupSocketConnection() {
    const user = JSON.parse(sessionStorage.getItem("currentUser"));
    const id = user?.id;
    const socket = io(`${environment.apiUrl}/socket`, {
      query: { id: id },
      transports: ['websocket', 'polling'],
    });

    // Handle connection errors
    socket.on("connect_error", (error) => {
      console.error("Connection failed:", error);
    });

    socket.on("disconnect", (reason) => {
      console.log("Disconnected:", reason);
    });

    return socket;
  }

  getFacilityDashboard(form) {
    return this._http.post<any>(
      `${environment.apiUrl}/api/dashboard/all`,
      form
    ).pipe(tap((data)=>console.log("Facility Dashboard: ", data)));
  }

  getTotalData(form) {
    return this._http.post<any>(
      `${environment.apiUrl}/api/dashboard/total/all`,
      form
    );
  }

  getPatientsChartData(form) {
    return this._http.post<any>(
      `${environment.apiUrl}/api/dashboard/patients/all`,
      form
    );
  }

  getSideData(form: any) {
    return this._http.post<any>(
      `${environment.apiUrl}/api/dashboard/patients/sideData`,
      form
    );
  }

  getTotalDetails(id: number): Observable<Details> {
    return this._http.get<Details>(
      `${environment.apiUrl}/api/dashboard/total/details?id=${id}`
    );
  }

  getAllStaffs(form: any): Observable<any> {
    return this._http.post<any>(
      `${environment.apiUrl}/api/dashboard/productivity/getAllStaffs`,
      form
    );
  }

  getAllByOrganization(orgKey: any) {
    return this._http.get<any>(
      `${environment.apiUrl}/api/department/getByOrganization/${orgKey}`
    );
  }

  getDeptById(id: any) {
    return this._http.get<any>(
      `${environment.apiUrl}/api/department/get/dept/${id}`
    );
  }

  getAllDept() {
    return this._http.get<any>(`${environment.apiUrl}/api/department/get/all`);
  }

  getFirstNStaffs(
    form: any,
    limit: number,
    offset: number
  ): Observable<Staffs> {
    return this._http.post<Staffs>(
      `${environment.apiUrl}/api/dashboard/productivity/getFirstNStaffs/${limit}/${offset}`,
      form
    );
  }

  export(data: any): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this._http.post<any>(
      `${environment.apiUrl}/api/receive-data`,
      data,
      { headers, responseType: "arraybuffer" as "json" }
    );
  }

  exportProductivityData(data: any): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this._http.post<any>(
      `${environment.apiUrl}/api/receive-data/realtime`,
      data,
      { headers, responseType: "arraybuffer" as "json" }
    );
  }

  getRealTimeData(id: any) {
    return io(`${environment.apiUrl}/socket`, { query: { id: id } });
  }

  getNotificationPreferences() {
    return this._http.get<Preferences>(
      `${environment.apiUrl}/api/notifications/preferences`
    );
  }
}
