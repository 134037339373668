import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ElementRef,
  Renderer2,
  AfterViewInit,
  HostListener,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as Waves from "node-waves";
import { CoreMenuService } from "@core/components/core-menu/core-menu.service";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CoreConfigService } from "@core/services/config.service";
import { CoreLoadingScreenService } from "@core/services/loading-screen.service";
import { menu } from "app/menu/menu";
import { LoaderService } from "../@core/services/loader.service";
import { coreConfig } from "./app-config";
import { AuthenticationService } from "./auth/service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  coreConfig: any;
  menu: any;
  private _unsubscribeAll: Subject<any>;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _title: Title,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    public _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    private _loaderService: LoaderService,
    private _coreLoadingScreenService: CoreLoadingScreenService,
    private _coreMenuService: CoreMenuService,
    private authService: AuthenticationService
  ) {
    this.menu = menu;
    this._coreConfigService.config = coreConfig;
    this._coreMenuService.register("main", this.menu);
    this._coreMenuService.setCurrentMenu("main");
    this._unsubscribeAll = new Subject();
  }
  
  @HostListener("window:click") 
  @HostListener("window:mousemove")
  @HostListener("window:scroll")
  @HostListener("window:keyup")
  @HostListener("window:keydown")
  @HostListener("window:keypress") 
  refreshUserState() {
    // console.log("User activity detected");
    this.authService.resetTimer();
  }

  ngOnInit(): void {
    this.authService.startActivityListener();
    Waves.init();
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
        this._elementRef.nativeElement.classList.remove(
          "vertical-layout",
          "vertical-menu-modern",
          "horizontal-layout",
          "horizontal-menu"
        );
        if (this.coreConfig.layout.type === "vertical") {
          this._elementRef.nativeElement.classList.add(
            "vertical-layout",
            "vertical-menu-modern"
          );
        } else if (this.coreConfig.layout.type === "horizontal") {
          this._elementRef.nativeElement.classList.add(
            "horizontal-layout",
            "horizontal-menu"
          );
        }
        this._elementRef.nativeElement.classList.remove(
          "navbar-floating",
          "navbar-static",
          "navbar-sticky",
          "navbar-hidden"
        );
        if (this.coreConfig.layout.navbar.type === "navbar-static-top") {
          this._elementRef.nativeElement.classList.add("navbar-static");
        } else if (this.coreConfig.layout.navbar.type === "fixed-top") {
          this._elementRef.nativeElement.classList.add("navbar-sticky");
        } else if (this.coreConfig.layout.navbar.type === "floating-nav") {
          this._elementRef.nativeElement.classList.add("navbar-floating");
        } else {
          this._elementRef.nativeElement.classList.add("navbar-hidden");
        }
        this._elementRef.nativeElement.classList.remove(
          "footer-fixed",
          "footer-static",
          "footer-hidden"
        );
        if (this.coreConfig.layout.footer.type === "footer-sticky") {
          this._elementRef.nativeElement.classList.add("footer-fixed");
        } else if (this.coreConfig.layout.footer.type === "footer-static") {
          this._elementRef.nativeElement.classList.add("footer-static");
        } else {
          this._elementRef.nativeElement.classList.add("footer-hidden");
        }
        if (
          this.coreConfig.layout.menu.hidden &&
          this.coreConfig.layout.navbar.hidden &&
          this.coreConfig.layout.footer.hidden
        ) {
          this._elementRef.nativeElement.classList.add("blank-page");
          this._renderer.setAttribute(
            this._elementRef.nativeElement.getElementsByClassName(
              "app-content"
            )[0],
            "style",
            "transition:none"
          );
        } else {
          this._elementRef.nativeElement.classList.remove("blank-page");
          setTimeout(() => {
            this._renderer.setAttribute(
              this._elementRef.nativeElement.getElementsByClassName(
                "app-content"
              )[0],
              "style",
              "transition:300ms ease all"
            );
          }, 0);
          if (this.coreConfig.layout.navbar.hidden) {
            this._elementRef.nativeElement.classList.add("navbar-hidden");
          }
          if (this.coreConfig.layout.menu.hidden) {
            this._renderer.setAttribute(
              this._elementRef.nativeElement,
              "data-col",
              "1-column"
            );
          } else {
            this._renderer.removeAttribute(
              this._elementRef.nativeElement,
              "data-col"
            );
          }
          if (this.coreConfig.layout.footer.hidden) {
            this._elementRef.nativeElement.classList.add("footer-hidden");
          }
        }
        if (
          this.coreConfig.layout.skin !== "" &&
          this.coreConfig.layout.skin !== undefined
        ) {
          this.document.body.classList.remove(
            "default-layout",
            "bordered-layout",
            "dark-layout",
            "semi-dark-layout"
          );
          this.document.body.classList.add(
            this.coreConfig.layout.skin + "-layout"
          );
        }
      });
    this._title.setTitle(this.coreConfig.app.appTitle);
  }

  ngAfterViewInit() {
    this._loaderService.httpProgress().subscribe((status: boolean) => {
      if (status) {
        //this.spinner.show();
      } else {
        //this.spinner.hide();
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }
}
