<div class="avatar-container mr-1">
    <span class="avatar">
        <div *ngIf="avatar !== null && avatar !== ''">
            <img class="rounded-circle" src="{{ avatar }}" height="38" width="38" alt="avatar" />
            <span class="avatar-status-online"></span>
        </div>
    </span>
    <span class="avatar">
        <div *ngIf="avatar === null || avatar === ''" class="rounded-square avatar-default-bg-color" [ngClass]="{
                      'bg-light-success': user?.isVerified,
                      'bg-light-danger': !user?.isActive,
                      'bg-light-warning': user?.isActive && !user?.isVerified
                    }">
            <span class="initials avatar-default-color">
                {{initials}}
            </span>
            <span class="avatar-status-online"></span>
        </div>
    </span>
</div>