import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class LogService {
  constructor(private http: HttpClient) {}

  logInfo(message: string): void {
    this.log("info", message);
  }

  logWarning(message: string): void {
    this.log("warning", message);
  }

  logError(message: string): void {
    this.log("error", message);
  }

  log(level: string, message: string) {
    console.log("LogService.log():", level, message);
    return this.http.post(`${environment.apiUrl}/api/logs`, { level, message }).subscribe(
      (response) => {
        console.log("LogService.log():", response);
      },
      (error) => {
        console.error("LogService.log():", error);
      }
    );
  }
}
