import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "environments/environment";
import { tap } from "rxjs/operators";
export interface Notifications {
  success: boolean;
  totalResults: number;
  unreadCount: number;
  notifications: Notification[];
}

export interface Notification {
  id: number;
  created: string;
  createdById: number;
  title: string;
  organizationId: number;
}

export interface NewNotifications {
  success: boolean;
  notification: Notification;
}

export interface NewNotification {
  Id: number;
  Created: string;
  CreatedById: number;
  Title: string;
  OrganizationId: number;
  FirstName: string;
  LastName: string;
  Avatar: string;
  isRead: boolean;
}
@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  private notificationsSubject = new Subject<Notifications>();
  private newNotificationSubject = new BehaviorSubject<Notification | null>(
    null
  );

  // Create a Subject that will send a signal when a notification is read
  private notificationRead = new Subject<any>();

  // Create an Observable that other components can subscribe to
  notificationRead$ = this.notificationRead.asObservable();

  constructor(private httpClient: HttpClient) {}

  get notifications$(): Observable<Notifications> {
    return this.notificationsSubject.asObservable();
  }

  get newNotification$(): Observable<Notification | null> {
    return this.newNotificationSubject.asObservable();
  }

  getNotificationsData(
    orgId: any,
    limit: any,
    offset: any
  ): Observable<Notifications> {
    return this.httpClient
      .get<Notifications>(
        `${environment.apiUrl}/api/notifications/get/${orgId}/${limit}/${offset}`
      )
      .pipe(
        tap((notifications: Notifications) => {
          this.notificationsSubject.next(notifications);
        })
      );
  }

  readNotification(id: any, userId: any): Observable<any> {
    return this.httpClient.put(
      `${environment.apiUrl}/api/notifications/read/${id}/${userId}`,
      {}
    );
  }

  notifyNewNotification(notification: Notification): void {
    this.newNotificationSubject.next(notification);
  }

  // Call this method when a notification is read
  signalNotificationRead(id: any) {
    this.notificationRead.next(id);
  }
}
