import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CoreCommonModule } from '../../../@core/common.module';
import { PatientInfoComponent } from './patient-info/patient-info.component';
import { CorePipesModule } from '../../../@core/pipes/pipes.module';
import { CoreDirectivesModule } from '../../../@core/directives/directives';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DischargeInfoComponent } from './discharge-info/discharge-info.component';
import { CallQuestionFormComponent } from './call-question-form/call-question-form.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DischargeStatusComponent } from './discharge-status/discharge-status.component';
import { FileUploadModule } from 'ng2-file-upload';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
@NgModule({
  declarations: [
    PatientInfoComponent,
    DischargeInfoComponent,
    CallQuestionFormComponent,
    DischargeStatusComponent,
    FileUploaderComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    Ng2FlatpickrModule,
    NgxDatatableModule,
    PerfectScrollbarModule,
    FileUploadModule,
    CoreCommonModule,
    CorePipesModule,
    CoreDirectivesModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    PatientInfoComponent,
    DischargeInfoComponent,
    DischargeStatusComponent,
    CallQuestionFormComponent,
    FileUploaderComponent
  ]
})
export class SharedModule { }
