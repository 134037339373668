import { NgModule } from '@angular/core';

import { FeatherIconDirective } from '@core/directives/core-feather-icons/core-feather-icons';
import { RippleEffectDirective } from '@core/directives/core-ripple-effect/core-ripple-effect.directive';
import { DateMaskDirective } from './date-mask.directive';
import { PhoneMaskDirective } from './phone-mask.directive';

@NgModule({
    declarations: [RippleEffectDirective, FeatherIconDirective, PhoneMaskDirective, DateMaskDirective],
    exports: [RippleEffectDirective, FeatherIconDirective, PhoneMaskDirective, DateMaskDirective]
})
export class CoreDirectivesModule {}
