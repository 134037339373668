// Socket.service.ts
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Socket, io } from "socket.io-client";

@Injectable({
  providedIn: 'root'
})
export class SocketService {

    private socketSubject: BehaviorSubject<Socket | null> = new BehaviorSubject<Socket | null>(null);

    socket$ = this.socketSubject.asObservable();
  
    constructor() {}

    connectSocket(user: { id: string }) {
      const socket = io(`${environment.apiUrl}/socket`, {
        query: { id: user.id },
      });
  
      this.socketSubject.next(socket);
    }

    // emit id to server
    emit(event: string, data: any) {
      const socket = this.socketSubject.value;
      // console.log('socket', socket);
      if (socket) {
        // console.log('emitting', event, data);
        socket.emit(event, data);
      }
    }
  
    disconnectSocket() {
      const socket = this.socketSubject.value;
      if (socket) {
        socket.disconnect();
        this.socketSubject.next(null);
      }
    }

    // event listener
    on(event: string, callback: (...args: any[]) => void) {
      const socket = this.socketSubject.value;
      if (socket) {
        socket.on(event, callback);
      }
    }

}