import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthenticationService } from "app/auth/service/authentication.service";
import { NotificationsService } from "app/layout/components/navbar/navbar-notification/notifications.service";
import { DashboardService } from "app/main/dashboards/dashboard.service";
import { Subscription } from "rxjs";
import { formatDistance } from "date-fns";
import { LogService } from "app/auth/service/log.service";

// Interface
export interface Notifications {
  success: boolean;
  totalResults: number;
  notifications: Notification[];
  unreadCount: number;
}

export interface Notification {
  id: number;
  created: string;
  createdById: number;
  title: string;
  organizationId: number;
}

@Component({
  selector: "app-navbar-notification",
  templateUrl: "./navbar-notification.component.html",
  styles: [
    `
      $avatar-size: 2.5rem;

      .rounded-square {
        background-color: #ccc;
        border-radius: 8%;
        height: $avatar-size;
        text-align: center;
        width: $avatar-size;
      }

      .initials {
        font-size: $avatar-size / 2;
        line-height: 1;
        position: relative;
        font-weight: bolder;
        top: $avatar-size / 4;
        color: black;
      }
    `,
  ],
})
export class NavbarNotificationComponent implements OnInit, OnDestroy {
  public currentUserRole: any;
  public orgId: any;
  public limit: any = 5;
  public offset: any = 0;
  public totalResults: any;
  public unreadCount: any;
  public userId: any;
  public notifications: Notification[] = [];
  private notificationsSubscription: Subscription;
  private subscription: Subscription;

  constructor(
    private _notificationsService: NotificationsService,
    private _authenticationService: AuthenticationService,
    private _dashboardService: DashboardService,
    private logService: LogService
  ) {
    if (this._authenticationService.currentUserValue.id) {
      this._dashboardService.setupSocketConnection();
    }
  }

  ngOnInit(): void {
    this.userId = this._authenticationService.currentUserValue.id;
    // console.log("User ID:", this.userId);
    if (!this.userId) {
      return;
    }
    if (sessionStorage.getItem("currentUser") !== null) {
      this.fetchNotificationsData();

      this.notificationsSubscription =
        this._notificationsService.notifications$.subscribe(
          (data: Notifications) => {
            // console.log(data);
            this.notifications = data.notifications;
            this.totalResults = data.totalResults;
            this.unreadCount = data.unreadCount;
          }, error => {
            this.logService.logError(`Error at line 91(navbar-notification.component.ts): ${error}`);
          }
        );

      // Subscribe to the notificationRead$ Observable
      this.subscription =
        this._notificationsService.notificationRead$.subscribe((id) => {
          // Update unreadCount when a notification is read
          this.readNotification(id);
        }, error => {
          this.logService.logError(`Error at line 101(navbar-notification.component.ts): ${error}`);
        });

      this._dashboardService
        .setupSocketConnection()
        .on(`newNotification-${this.userId}`, (data) => {
          // console.log("New notification:", data);

          // push the new notification to the notifications array at the beginning
          this.notifications.unshift(data.notification);
          // console.log(this.notifications);

          // increment the unread count and total results
          this.unreadCount++;
          this.totalResults++;
        });
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from subscriptions to prevent memory leaks
    this.notificationsSubscription.unsubscribe();
    this.subscription.unsubscribe();
  }

  fetchNotificationsData(): void {
    const orgId = this._authenticationService.currentUserValue.organization.id;
    if (!orgId) {
      return;
    }
    this._notificationsService
      .getNotificationsData(orgId, this.limit, this.offset)
      .subscribe(
        (data: Notifications) => {
          // Handle the fetched data here
          this.notifications = data.notifications;
          this.totalResults = data.totalResults;
          this.unreadCount = data.unreadCount;
          // console.log("Fetched notifications data:", data);
        },
        (error: any) => {
          // Handle error
          console.error("Error fetching notifications data:", error);
          this.logService.logError(`Error at line 144(navbar-notification.component.ts): ${error}`);
        }
      );
  }

  trackByFunction(index, item) {
    // Assuming 'item' has a unique identifier property 'id'
    return item.id; // Return the unique identifier for each item
  }
  
  readNotification(id: any): void {
    if (this.userId) {
      this._notificationsService.readNotification(id, this.userId).subscribe(
        (data: any) => {
          // console.log("Notification read:", data);

          // Handle the fetched data here
          this.fetchNotificationsData();
        },
        (error: any) => {
          // Handle error
          console.error("Error reading notification:", error);
          this.logService.logError(`Error at line 158(navbar-notification.component.ts): ${error}`);
        }
      );
    }
  }

  getTimeAgo(time: string): string {
    const date = new Date(time);
    const now = new Date();
    return formatDistance(date, now, { addSuffix: true });
  }
}
