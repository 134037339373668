export const environment = {
    production: true,
    apiUrl: 'https://api.callsnavigator.com',
    dischargeUploadUrl: `https://api.callsnavigator.com/api/fileUpload`,
    cognito: {
        "aws_project_region": "us-east-2",
        "aws_cognito_region": "us-east-2",
        "aws_user_pools_id": "us-east-2_xA00HAVKO",
        "authenticationFlowType": "USER_PASSWORD_AUTH",
        "aws_user_pools_web_client_id": "6q0haf8fnidmvdnd4navlpe5vs",
        "oauth": {
            "domain": "calls-navigator.auth.us-east-2.amazoncognito.com",
            "scope": [
                "phone",
                "email",
                "openid",
                "profile",
                "aws.cognito.signin.user.admin"
            ],
            "redirectSignIn": `${window.location.origin}/home`,
            "redirectSignOut": `${window.location.origin}`,
            "responseType": "code"
        }
    },
};
