import { CoreMenu } from "@core/types";
import { Role } from "../auth/models";

export const menu: CoreMenu[] = [
  // Dashboard
  {
    id: "facility-dashboard",
    title: "Dashboard",
    translate: "MENU.DASHBOARD",
    type: "collapsible",
    userRole: [
      Role.Super,
      Role.AltrixAdmin,
      Role.Manager,
      Role.Facility,
      Role.Reporting,
    ],
    icon: "home",
    badge: {
      title: "2",
      translate: "MENU.DASHBOARD",
      classes: "badge-light-warning badge-pill",
    },
    children: [
      {
        id: "facility-dashboard",
        title: "Analytics",
        translate: "MENU.DASHBOARD",
        type: "item",
        icon: "circle",
        url: "dashboard/facility",
        userRole: [
          Role.Super,
          Role.AltrixAdmin,
          Role.Manager,
          Role.Facility,
          Role.Reporting,
        ],
      },
      {
        id: "productivity-dashboard",
        title: "Productivity",
        translate: "MENU.DASHBOARD",
        type: "item",
        icon: "circle",
        url: "dashboard/productivity",
        userRole: [
          Role.Super,
          Role.AltrixAdmin,
          Role.Manager,
          Role.Facility,
        ],
      },
    ],
  },
  // {
  //   id: "assignment-toolbar",
  //   title: "Assignment Toolbar",
  //   translate: "MENU.DASHBOARD",
  //   type: "item",
  //   icon: "settings",
  //   url: "dashboard/assignment-toolbar",
  //   userRole: [Role.Super, Role.Manager],
  // },
  {
    id: "assignment-toolbar",
    title: "Assignment Toolbar",
    translate: "MENU.DASHBOARD",
    type: "item",
    icon: "settings",
    url: "pages/assignment-toolbar",
    userRole: [Role.Super, Role.Manager],
  },
  {
    id: "dashboard",
    title: "Assignments",
    translate: "MENU.DASHBOARD",
    type: "collapsible",
    icon: "book",
    badge: {
      title: "3",
      translate: "MENU.DASHBOARD",
      classes: "badge-light-warning badge-pill",
    },
    userRole: [Role.Super, Role.AltrixAdmin],
    children: [
      {
        id: "admin-dashboard",
        title: "All Assignments",
        translate: "MENU.DASHBOARD",
        type: "item",
        icon: "circle",
        url: "dashboard/admin",
        userRole: [Role.Super],
      },
      {
        id: "manager-dashboard",
        title: "Manager Assignments",
        translate: "MENU.DASHBOARD",
        type: "item",
        icon: "circle",
        url: "dashboard/manager",
        userRole: [Role.Super, Role.AltrixAdmin],
      },
      {
        id: "nurse-dashboard",
        title: "Employee Assigments",
        translate: "MENU.DASHBOARD",
        type: "item",
        icon: "circle",
        url: "dashboard/nurse",
        userRole: [Role.Super, Role.AltrixAdmin],
      },
    ],
  },
  {
    id: "dashboard",
    title: "Assignments",
    translate: "MENU.DASHBOARD",
    type: "collapsible",
    icon: "book",
    badge: {
      title: "2",
      translate: "MENU.DASHBOARD",
      classes: "badge-light-warning badge-pill",
    },
    userRole: [Role.Manager],
    children: [
      {
        id: "manager-dashboard",
        title: "Manager Assignments",
        translate: "MENU.DASHBOARD",
        type: "item",
        icon: "circle",
        url: "dashboard/manager",
        userRole: [Role.Manager],
      },
      {
        id: "nurse-dashboard",
        title: "Employee Assigments",
        translate: "MENU.DASHBOARD",
        type: "item",
        icon: "circle",
        url: "dashboard/nurse",
        userRole: [Role.Manager],
      },
    ],
  },
  {
    id: "nurse-dashboard",
    title: "Employee Assigments",
    translate: "MENU.DASHBOARD",
    type: "item",
    icon: "home",
    url: "dashboard/nurse",
    userRole: [Role.Nurse, Role.Employee],
  },
  {
    id: "discharges",
    title: "Discharges",
    translate: "MENU.APPS.DISCHARGES",
    type: "item",
    icon: "list",
    url: "pages/discharges",
    userRole: [
      Role.Super,
      Role.Nurse,
      Role.Employee,
      Role.Manager,
      Role.AltrixAdmin,
    ],
  },
  {
    id: "patients",
    title: "Patients",
    translate: "MENU.PAGES.PATIENTS",
    type: "item",
    icon: "user",
    url: "pages/patients",
    userRole: [Role.Super, Role.Manager, Role.Employee, Role.AltrixAdmin],
  },
  {
    id: "manage",
    type: "section",
    title: "Manage",
    translate: "MENU.PAGES.SECTION",
    icon: "layers",
    userRole: [
      Role.Super,
      Role.Nurse,
      Role.Employee,
      Role.Manager,
      Role.AltrixAdmin,
    ],
    children: [
      {
        id: "tasks",
        title: "Tasks",
        translate: "MENU.PAGES.SECTION",
        type: "item",
        icon: "check-square",
        url: "pages/tasks",
        userRole: [
          Role.Super,
          Role.Nurse,
          Role.Employee,
          Role.Manager,
          Role.AltrixAdmin,
        ],
      },
      {
        id: "notifications",
        title: "Notifications",
        translate: "MENU.PAGES.SECTION",
        type: "item",
        icon: "bell",
        url: "pages/notifications",
        userRole: [Role.Super, Role.Manager, Role.AltrixAdmin],
      },
      {
        id: "knowledge-base",
        title: "Knowledge Base",
        translate: "MENU.PAGES.SECTION",
        type: "item",
        icon: "file-text",
        url: "pages/knowledge-base",
        userRole: [Role.Super, Role.Manager, Role.Employee, Role.AltrixAdmin],
      },
    ],
  },
  {
    id: "admin",
    type: "section",
    title: "Admin",
    translate: "MENU.PAGES.SECTION",
    icon: "layers",
    userRole: [
      Role.Super,
      Role.Nurse,
      Role.Employee,
      Role.Manager,
      Role.AltrixAdmin,
    ],
    children: [
      {
        id: "departments",
        title: "Departments",
        translate: "MENU.PAGES.DEPARTMENTS",
        type: "item",
        icon: "grid",
        url: "pages/departments",
        userRole: [
          Role.Super,
          Role.Manager,
          Role.AltrixAdmin,
          Role.Nurse,
          Role.Employee,
        ],
      },
      {
        id: "flags",
        title: "Flags",
        translate: "MENU.PAGES.FLAGS",
        type: "item",
        icon: "flag",
        url: "pages/flags",
        userRole: [Role.Super, Role.Manager, Role.AltrixAdmin],
      },
      {
        id: "organizations",
        title: "Organizations",
        translate: "MENU.PAGES.ORGANIZATIONS",
        type: "item",
        icon: "server",
        url: "pages/organizations",
        userRole: [Role.Super, Role.AltrixAdmin],
      },
      {
        id: "physicians",
        title: "Physicians",
        translate: "MENU.PAGES.PHYSICIANS",
        type: "item",
        icon: "activity",
        url: "pages/physicians",
        userRole: [
          Role.Super,
          Role.Nurse,
          Role.Employee,
          Role.Manager,
          Role.AltrixAdmin,
        ],
      },
      {
        id: "staff",
        title: "Staff",
        translate: "MENU.PAGES.STAFF",
        type: "item",
        icon: "users",
        url: "pages/staff",
        userRole: [Role.Super, Role.Manager, Role.AltrixAdmin],
      },
      {
        id: "template-builder",
        title: "Template Builder",
        translate: "MENU.APPS.TEMPLATEBUILDER",
        type: "item",
        icon: "layout",
        url: "pages/template-builder",
        userRole: [
          Role.Super,
          Role.Manager,
          Role.Nurse,
          Role.Employee,
          Role.AltrixAdmin,
        ],
      },
    ],
  },
  {
    id: "archived",
    title: "Archived",
    translate: "MENU.DASHBOARD",
    type: "collapsible",
    icon: "archive",
    badge: {
      title: "2",
      translate: "MENU.DASHBOARD",
      classes: "badge-light-warning badge-pill",
    },
    userRole: [Role.Super],
    children: [
      {
        id: "archive-discharges",
        title: "Discharges",
        translate: "MENU.DASHBOARD",
        type: "item",
        icon: "circle",
        url: "dashboard/archived-discharges",
        userRole: [Role.Super],
      },
      {
        id: "archived-patients",
        title: "Patients",
        translate: "MENU.DASHBOARD",
        type: "item",
        icon: "circle",
        url: "pages/archived-patients",
        userRole: [Role.Super],
      },
    ],
  },
];
