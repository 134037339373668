import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreCommonModule } from '../../common.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AvatarComponent } from './avatar.component';



@NgModule({
    declarations: [AvatarComponent],
    imports: [CommonModule, NgbModule, CoreCommonModule],
    exports: [AvatarComponent]
})
export class AvatarModule { }
