<!-- vertical-layout -->
<ngx-bootstrap-spinner bdColor="rgba(51,51,51,0.8)" size="default" loadingText="Loading..." color="#fff" type="border">
</ngx-bootstrap-spinner>
<ngx-bootstrap-spinner bdColor="rgba(51,51,51,0.8)" size="default" loadingText="Uploading..." color="#fff"
    name="uploadSpinner" type="grow">
</ngx-bootstrap-spinner>

<ng-container *ngIf="coreConfig.layout.type === 'vertical'">
    <vertical-layout></vertical-layout>
</ng-container>
<!-- / vertical-layout -->
<!-- horizontal-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'horizontal'">
    <horizontal-layout></horizontal-layout>
</ng-container>
<!-- / horizontal-layout -->
<!-- theme customizer -->
<core-sidebar name="themeCustomizer" class="customizer d-none d-md-block" [invisibleOverlay]="true"
    *ngIf="coreConfig.layout.customizer">
    <a class="customizer-toggle d-flex align-items-center justify-content-center"
        (click)="toggleSidebar('themeCustomizer')">
        <span [data-feather]="'settings'" [class]="'spinner'"></span>
    </a>
    <core-theme-customizer></core-theme-customizer>
</core-sidebar>
<!-- / theme customizer -->