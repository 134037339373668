import { Component, Input, OnInit } from "@angular/core";
import { Role, User } from "../../../app/auth/models";

@Component({
  selector: "core-avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent implements OnInit {
  @Input() public user: User;

  public initials: string;
  public avatar: string;

  constructor() {}

  ngOnInit(): void {
    if (this.user) {
      this.initials =
        this.user.firstName[0].toUpperCase() +
        this.user.lastName[0].toUpperCase();
      // this.avatar = this.user.avatar ? this.user.avatar : sessionStorage.getItem("avatar");
      this.avatar = sessionStorage.getItem("avatar") == 'null' ? JSON.parse(sessionStorage.getItem("avatar")) : sessionStorage.getItem("avatar");
      // console.log(this.avatar);
    }
  }
}
