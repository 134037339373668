import { Component, Input } from '@angular/core';

import { CoreMenuItem } from '@core/types';
import { CoreMenuService } from '../../core-menu.service';

@Component({
  selector: '[core-menu-vertical-section]',
  templateUrl: './section.component.html'
})
export class CoreMenuVerticalSectionComponent {
  @Input()
  item: CoreMenuItem;

    constructor(private _coreMenuService: CoreMenuService) { }

    get role() {
        return this._coreMenuService.currentUser?.role;
    }
}
