import { AbstractControl } from "@angular/forms";
import { Params } from "@angular/router";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { CallStatus } from "../../app/main/pages/discharges/callStatus";
import { DischargeFilter } from "../../app/main/pages/discharges/dischargeFilter";

export const APP_NAME = 'Call Navigator';

export function calculateAge(dateString) { // a date on string "22/10/1988
    // let age: number;
    // var dateParts = dateString.split("-");
    // var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    // var dateObject = new Date(dateString);
    // if (dateString) {
    //     var timeDiff = Math.abs(Date.now() - new Date(dateObject).getTime());
    //     age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    // }
    // return age;
    const today = new Date(); // Current date
    const birthDate = new Date(dateString); // Date of birth
  
    // Calculate the age
    let age = today.getFullYear() - birthDate.getFullYear();
  
    // Check if the birthday has already occurred this year
    const currentMonth = today.getMonth();
    const birthMonth = birthDate.getMonth();
    if (currentMonth < birthMonth || (currentMonth === birthMonth && today.getDate() < birthDate.getDate())) {
      age--; // Subtract 1 from age if the birthday hasn't occurred yet this year
    }
  
    return age;
}


export function getDateDifferenceInDays(date1: Date, date2: Date) {
    if (date1 && date2) {
        const diffInMs = Math.abs(date2.valueOf() - date1.valueOf());
        const date = diffInMs / (1000 * 60 * 60 * 24);
        return date.toFixed(2);
    }    
}

export function getDateDifferenceInHours(date1: Date, date2: Date) {
    const diffInMs = Math.abs(date2.valueOf() - date1.valueOf());
    const date = diffInMs / (1000 * 60 * 60);
    return date.toFixed(2);
}

export function getDateDifferenceInMinutes(date1: Date, date2: Date) {
    const diffInMs = Math.abs(date2.valueOf() - date1.valueOf());
    const date = diffInMs / (1000 * 60);
    return date.toFixed(2);
}

export function getDateDifferenceInSeconds(date1: Date, date2: Date) {
    const diffInMs = Math.abs(date2.valueOf() - date1.valueOf());
    const date = diffInMs / 1000;
    return date.toFixed(2);
}

export function formatFormDate(ngbFormDate) {
    if (ngbFormDate) {
        return new Date(ngbFormDate.year, ngbFormDate.month - 1, ngbFormDate.day).toISOString();        
    }

    return null;
}

export function formatedDate(ngbFormDate) {
    if (ngbFormDate && ngbFormDate.year && ngbFormDate.month && ngbFormDate.day) {
        // return `${ngbFormDate.year}-${String(ngbFormDate.month).padStart(2, '0')}-${String(ngbFormDate.day).padStart(2, '0')}T00:00:00.000Z`;
        return `${ngbFormDate.year}-${String(ngbFormDate.month).padStart(2, '0')}-${String(ngbFormDate.day).padStart(2, '0')}`;
    }

    return null;
}

export function formatedDates(ngbFormDate) {
    if (ngbFormDate && ngbFormDate.year && ngbFormDate.month && ngbFormDate.day) {
        return `${String(ngbFormDate.month).padStart(2, '0')}-${String(ngbFormDate.day).padStart(2, '0')}-${ngbFormDate.year}`;
    }

    return null;
}


export function formatedFormDate(ngbFormDate) {
    if (ngbFormDate && ngbFormDate.year && ngbFormDate.month && ngbFormDate.day) {
        return `${ngbFormDate.year}-${String(ngbFormDate.month).padStart(2, '0')}-${String(ngbFormDate.day).padStart(2, '0')}T00:00:00.000Z`;
        // return `${ngbFormDate.year}-${String(ngbFormDate.month).padStart(2, '0')}-${String(ngbFormDate.day).padStart(2, '0')}`;
    }

    return null;
}

export function formatedtoFormDate(ngbFormDate) {
    if (ngbFormDate && ngbFormDate.year && ngbFormDate.month && ngbFormDate.day) {
        return `${ngbFormDate.year}-${String(ngbFormDate.month).padStart(2, '0')}-${String(ngbFormDate.day).padStart(2, '0')}T23:59:59.000Z`;
        // return `${ngbFormDate.year}-${String(ngbFormDate.month).padStart(2, '0')}-${String(ngbFormDate.day).padStart(2, '0')}`;
    }

    return null;
}

export function formatFormDatesFromIsoToNgb(form: AbstractControl, selector: string) {
    var date = new Date(form.get(selector).value).toISOString();
    var dateParts = date.split('-');
    if (date) {
        var newDate = new NgbDate(parseInt(dateParts[0]), parseInt(dateParts[1]), parseInt(dateParts[2].split('T')[0]));
        form.get(selector).patchValue(newDate);
    }
}

export function formatDateToNgb(date: Date): NgbDate {
    var dateParts = date.toISOString().split('-');
    if (date) {
        return new NgbDate(parseInt(dateParts[0]), parseInt(dateParts[1]), parseInt(dateParts[2].split('T')[0]));
    }
}

export function getEnumKeyByEnumValue(myEnum: any, enumValue: number | string): string {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : '';
}

export function getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);

    return previous;
}

export function randomCharGenerator(len, codeType) {
    var text = "";
    // *TODO*: replace with more secure password generation
    var charset = "abcdefghijklmnopqrstuvwxyz";
    switch (codeType) {
        case "upper":
            charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            break;
        case "number":
            charset = "01234567890";
            break;
        case "symbol":
            charset = "!@#$^&*()";
            break;
    }

    for (var i = 0; i < len; i++)
        text += charset.charAt(Math.floor(Math.random() * charset.length));

    return text;
}

export function generateRandomPassword(len) {
   
    const randomPassword =  randomCharGenerator(len/4, "lower") +
        randomCharGenerator(len / 4, "upper") +
        randomCharGenerator(len / 4, "number") +
        randomCharGenerator(len / 4, "symbol");

    const shuffle = str => [...str].sort(() => Math.random() - .5).join('');
    return shuffle(<string>randomPassword);
}

export function formatToCognitoPhoneNumber(phoneNumberString, countryCode = "1") {
    phoneNumberString = countryCode + phoneNumberString;
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '').trim();
        return [intlCode, match[2], match[3], , match[4]].join('').trim();
    }
    return null;
}

export function getDischargeQueryParamByDischargeFilter(dischargeFilters): Params{
    if (dischargeFilters.status === '') {
        dischargeFilters.status = null;
    }    
    if (dischargeFilters.assigneeId === '' || Number.isNaN(dischargeFilters.assigneeId)) {        
        dischargeFilters.assigneeId = null;
    }

    return {
        status: dischargeFilters.status,
        subStatus: dischargeFilters.subStatus,
        completedType: dischargeFilters.completedType,
        assigneeId: dischargeFilters.assigneeId,
        followUpRequired: dischargeFilters.followUpRequired,
        admittedDate: dischargeFilters.admittedDate,
        dischargeDate: dischargeFilters.dischargeDate
    };
}