import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';

@Injectable()
 export class LoaderInterceptor implements HttpInterceptor {

    private count = 0;

    constructor(private loaderService: LoaderService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.count === 0) {            
            this.loaderService.setHttpProgressStatus(true);
        }
        this.count++;
        return next.handle(req).pipe(
            finalize(() => {
                this.count--;
                if (this.count === 0) {
                    this.loaderService.setHttpProgressStatus(false);
                }
            }));
    }
}
