import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError, timer } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "environments/environment";
import { User, Role } from "app/auth/models";
import { GlobalConfig, ToastrService } from "ngx-toastr";
import { APP_NAME } from "../../../@core/common/utilities";
import { Router } from "@angular/router";
import { Auth } from "aws-amplify";
import { DOCUMENT } from "@angular/common";
import { NgxSpinnerService } from "ngx-bootstrap-spinner";
import { RealTime } from "app/main/dashboards/productivity-dashboard/productivity-dashboard.component";
import { SocketService } from "./socket.service";
import { LogService } from "./log.service";

const TIMEOUT = 15 * 60 * 1000; // 15 minutes in milliseconds

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;
  cognitoUser: any;
  private timer: any;
  socket: any;
  public selectedOption: number;
  public rows: RealTime;
  private userActivityTimer;

  //private
  private currentUserSubject: BehaviorSubject<User>;
  private options: GlobalConfig;

  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    private router: Router,
    private _spinner: NgxSpinnerService,
    @Inject(DOCUMENT) private document: Document,
    private socketService: SocketService,
    private logService: LogService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(sessionStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.options = this._toastrService.toastrConfig;
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public setCurrentUserSubject(user: User) {
    return this.currentUserSubject.next(user);
  }

  public updateCurrentUser(newUser) {
    //update the current user/ this sets new orgs/departments etc for interceptor to pass to api for validation
    sessionStorage.setItem("currentUser", JSON.stringify(newUser));
    this.currentUserSubject.next(newUser);
  }

  get isSuper() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Super
    );
  }

  get isAltrixAdmin() {
    return (
      this.currentUser &&
      this.currentUserSubject.value.role === Role.AltrixAdmin
    );
  }

  get isAdmin() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Manager
    );
  }

  get isNurse() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Nurse
    );
  }

  get isFacility() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Facility
    );
  }

  get isLocked() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Locked
    );
  }

  getisReporting() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Reporting
    );
  }

  login(email: string, username: string) {
    return this._http
      .post<any>(
        `${environment.apiUrl}/api/user/login`,
        { email, username }, { withCredentials: true }
      )
      .pipe(
        map((user: any) => {
          // localStorage.removeItem('currentUser');
          sessionStorage.removeItem("currentUser");
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            sessionStorage.setItem("currentUser", JSON.stringify(user));
            sessionStorage.setItem("firstName", user.firstName);
            sessionStorage.setItem("lastName", user.lastName);
            sessionStorage.setItem("avatar", user.avatar);

            // this.socket = io(`${environment.apiUrl}/socket`, {
            //   query: { id: user.id },
            // });
            this.socketService.connectSocket({ id: user.id });
            this.socketService.emit("login", { id: user.id });

            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                "You have successfully logged in as an " +
                  user.role +
                  " user to " +
                  APP_NAME +
                  ". Have a great day! 🎉",
                "👋 Welcome, " + user.firstName + "!",
                { toastClass: "toast ngx-toastr", closeButton: true }
              );
            }, 4500);
            this.router.navigate(["/dashboard"], { replaceUrl: true });
            this.currentUserSubject.next(user);
            this.startTimer();
          }
          return user;
        }),
        catchError((error) => {
          // Handle error here
          console.error("Login failed:", error);
          this.logService.logError(`Error at line 153(authentication.service.ts): ${error}`);
          // Show error toast message
          this._toastrService.error(
            "Login failed. Please try again later.",
            "Error",
            { toastClass: "toast ngx-toastr", closeButton: true }
          );
          return throwError(error);
        })
      );
  }

  logout() {
    this._spinner.show();
    this._http
      .post<any>(
        `${environment.apiUrl}/api/user/logout`,
        {}
      )
      .subscribe((data) => {
        // console.log("Data", data);
        Auth.signOut({ global: true })
          .then((data) => {
            // console.log("Data:", data);
            // this.router.navigate([""]);
            this._spinner.hide();
            this.currentUserSubject.next(null);
            if (this.socket) {
              this.socket.disconnect();
            }
            // sessionStorage.clear();
            sessionStorage.removeItem("currentUser");
            sessionStorage.removeItem("departments");
            sessionStorage.removeItem("employeeadmittedDate");
            sessionStorage.removeItem("employeeserviceDate");
            sessionStorage.removeItem("employeeassigneeId");
            sessionStorage.removeItem("manageradmittedDate");
            sessionStorage.removeItem("managerserviceDate");
            sessionStorage.removeItem("managerassigneeId");
            sessionStorage.removeItem("archiveddischargesadmittedDate");
            sessionStorage.removeItem("archiveddischargesserviceDate");
            sessionStorage.removeItem("archiveddischargesassigneeId");
            sessionStorage.removeItem("archiveddischargestatus");
            sessionStorage.removeItem("archiveddischargesubstatus");
            sessionStorage.removeItem("archiveddischargecompletedType");
            sessionStorage.removeItem("adminadmittedDate");
            sessionStorage.removeItem("adminserviceDate");
            sessionStorage.removeItem("adminassigneeId");
            sessionStorage.removeItem("dischargesadmittedDate");
            sessionStorage.removeItem("dischargesserviceDate");
            sessionStorage.removeItem("activeDepartment");
            sessionStorage.removeItem("activeDepartment1");
            sessionStorage.removeItem("fromDate");
            sessionStorage.removeItem("fromDates");
            sessionStorage.removeItem("fromDates1");
            sessionStorage.removeItem("toDate");
            sessionStorage.removeItem("toDates");
            sessionStorage.removeItem("toDates1");
            sessionStorage.removeItem("avatar");
            sessionStorage.removeItem("firstName");
            sessionStorage.removeItem("lastName");
            sessionStorage.removeItem("organizationId");
            sessionStorage.removeItem("organizationName");
            sessionStorage.removeItem("organizationId1");
            sessionStorage.removeItem("organizationName1");
            sessionStorage.removeItem("showFlaggedDetails");
            sessionStorage.removeItem("flagIndex");
            sessionStorage.removeItem("flagName");
            sessionStorage.removeItem("flagid");
            sessionStorage.removeItem("Todolimit");
            sessionStorage.removeItem("sortByPhysician");
            sessionStorage.removeItem("physiciancurrentPage");
            sessionStorage.removeItem("deptoffset");
            sessionStorage.removeItem("templateoffset");
            sessionStorage.removeItem("physicianoffset");
            sessionStorage.removeItem("physicianlimit");
            sessionStorage.removeItem("sortOrderPhysician");
            sessionStorage.removeItem("sortPhysianParams");
            sessionStorage.removeItem("templatecurrentPage");
            sessionStorage.removeItem("templatelimit");
            sessionStorage.removeItem("deptlimit");
            sessionStorage.removeItem("deptcurrentPage");
            sessionStorage.removeItem("sortOrgParams");
            sessionStorage.removeItem("staffoffset");
            sessionStorage.removeItem("staffcurrentPage");
            sessionStorage.removeItem("stafflimit");
            sessionStorage.removeItem("sortOrderStaffParams");
            sessionStorage.removeItem("sortByStaffParams");
            sessionStorage.removeItem("templateSortBy");
            sessionStorage.removeItem("templateSortOrder");
            sessionStorage.removeItem("patientcurrentPage");
            sessionStorage.removeItem("patientlimit");
            sessionStorage.removeItem("patientoffset");
            sessionStorage.removeItem("sortPatientParams");
            sessionStorage.removeItem("patientSortBy");
            sessionStorage.removeItem("patientSortOrder");
            sessionStorage.removeItem("archivedpatientcurrentPage");
            sessionStorage.removeItem("archivedpatientlimit");
            sessionStorage.removeItem("archivedpatientoffset");
            sessionStorage.removeItem("sortArchivedPatientParams");
            sessionStorage.removeItem("sortByArchivedPatientParams");
            sessionStorage.removeItem("sortOrderArchivedPatientParams");
            sessionStorage.removeItem("sortDeptParams");
            sessionStorage.removeItem("flagscurrentPage");
            sessionStorage.removeItem("flagslimit");
            sessionStorage.removeItem("flagsoffset");
            sessionStorage.removeItem("sortByFlag");
            sessionStorage.removeItem("sortOrderFlag");
            sessionStorage.removeItem("sortFlagParams");
            sessionStorage.removeItem("orgId");
            sessionStorage.removeItem("orgName");
            sessionStorage.removeItem("archivedorgId");
            sessionStorage.removeItem("archivedorgName");
            sessionStorage.removeItem("archiveddischargepageNumber");
            sessionStorage.removeItem("archiveddischargepageSize");
            sessionStorage.removeItem("archiveddischargesortBy");
            sessionStorage.removeItem("archiveddischargesortOrder");
            sessionStorage.removeItem("sortBy");
            sessionStorage.removeItem("sortOrder");
            sessionStorage.removeItem("employeeOrgId");
            sessionStorage.removeItem("employeeOrgName");
            sessionStorage.removeItem("managerOrgId");
            sessionStorage.removeItem("managerOrgName");
            sessionStorage.removeItem("adminOrgId");
            sessionStorage.removeItem("adminOrgName");
            sessionStorage.removeItem("dischargesOrgId");
            sessionStorage.removeItem("dischargesOrgName");
          })
          .catch(
            (err) =>{ 
              console.error(err);
              this.logService.logError(`Error at line 284(authentication.service.ts): ${err}`);
            });
      });
  }

  private startTimer() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      // Check if user is already logged out
      if (!this.currentUserValue) {
        return;
      }
      this.logout();
      this._toastrService.warning(
        "You have been logged out due to inactivity.",
        "Session Timeout",
        { toastClass: "toast ngx-toastr", closeButton: true }
      );
    }, TIMEOUT);
  }

  public resetTimer() {
    this.startTimer();

    // // if there's an existing timer, clear it
    // if (this.userActivityTimer) {
    //   this.userActivityTimer.unsubscribe();
    // }

    // // set a timer for 15 minutes
    // this.userActivityTimer = timer(TIMEOUT).subscribe(() => {
    //   // when the timer expires, log out the user
    //   // this.logout();
    //   console.log("User has been inactive for 15 minutes.");
    // });
  }

  resetPassword(token: string, password: string, confirmPassword: string) {
    //Should not reset untill email link is clicked to confirm its then, untill then we keep same password and data
    return this._http.post(`${environment.apiUrl}/accounts/reset-password`, {
      token,
      password,
      confirmPassword,
    });
  }

  handleUserActivity() {
    // Reset the timer
    this.resetTimer();
  }

  startActivityListener() {
    this.document.addEventListener("mousemove", () =>
      this.handleUserActivity()
    );
    this.document.addEventListener("keydown", () => this.handleUserActivity());
    this.document.addEventListener("scroll", () => this.handleUserActivity());
    this.document.addEventListener("click", () => this.handleUserActivity());
  }
}
