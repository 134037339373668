import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { LogService } from "../service/log.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private logService: LogService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if ([401, 403].includes(err.status)) {
          if (err.status === 401) {
            sessionStorage.removeItem("currentUser");
            sessionStorage.removeItem("cognitoCurrentUser");
            sessionStorage.removeItem("firstName");
            sessionStorage.removeItem("avatar");
            sessionStorage.removeItem("lastName");
            sessionStorage.removeItem("departments");
            sessionStorage.removeItem("employeeadmittedDate");
            sessionStorage.removeItem("employeeserviceDate");
            sessionStorage.removeItem("employeeassigneeId");
            sessionStorage.removeItem("manageradmittedDate");
            sessionStorage.removeItem("managerserviceDate");
            sessionStorage.removeItem("managerassigneeId");
            sessionStorage.removeItem("archiveddischargesadmittedDate");
            sessionStorage.removeItem("archiveddischargesserviceDate");
            sessionStorage.removeItem("archiveddischargesassigneeId");
            sessionStorage.removeItem("archiveddischargestatus");
            sessionStorage.removeItem("archiveddischargesubstatus");
            sessionStorage.removeItem("archiveddischargecompletedType");
            sessionStorage.removeItem("adminadmittedDate");
            sessionStorage.removeItem("adminserviceDate");
            sessionStorage.removeItem("adminassigneeId");
            sessionStorage.removeItem("dischargesadmittedDate");
            sessionStorage.removeItem("dischargesserviceDate");
            sessionStorage.removeItem("activeDepartment");
            sessionStorage.removeItem("activeDepartment1");
            sessionStorage.removeItem("fromDate");
            sessionStorage.removeItem("fromDates");
            sessionStorage.removeItem("fromDates1");
            sessionStorage.removeItem("toDate");
            sessionStorage.removeItem("toDates");
            sessionStorage.removeItem("toDates1");
            sessionStorage.removeItem("organizationId");
            sessionStorage.removeItem("organizationName");
            sessionStorage.removeItem("organizationId1");
            sessionStorage.removeItem("organizationName1");
            sessionStorage.removeItem("showFlaggedDetails");
            sessionStorage.removeItem("flagIndex");
            sessionStorage.removeItem("flagName");
            sessionStorage.removeItem("flagid");
            sessionStorage.removeItem("Todolimit");
            sessionStorage.removeItem("sortByPhysician");
            sessionStorage.removeItem("physiciancurrentPage");
            sessionStorage.removeItem("deptoffset");
            sessionStorage.removeItem("templateoffset");
            sessionStorage.removeItem("physicianoffset");
            sessionStorage.removeItem("physicianlimit");
            sessionStorage.removeItem("sortOrderPhysician");
            sessionStorage.removeItem("sortPhysianParams");
            sessionStorage.removeItem("templatecurrentPage");
            sessionStorage.removeItem("templatelimit");
            sessionStorage.removeItem("deptlimit");
            sessionStorage.removeItem("deptcurrentPage");
            sessionStorage.removeItem("sortOrgParams");
            sessionStorage.removeItem("staffoffset");
            sessionStorage.removeItem("staffcurrentPage");
            sessionStorage.removeItem("stafflimit");
            sessionStorage.removeItem("sortOrderStaffParams");
            sessionStorage.removeItem("sortByStaffParams");
            sessionStorage.removeItem("templateSortBy");
            sessionStorage.removeItem("templateSortOrder");
            sessionStorage.removeItem("patientcurrentPage");
            sessionStorage.removeItem("patientlimit");
            sessionStorage.removeItem("patientoffset");
            sessionStorage.removeItem("sortPatientParams");
            sessionStorage.removeItem("patientSortBy");
            sessionStorage.removeItem("patientSortOrder");
            sessionStorage.removeItem("archivedpatientcurrentPage");
            sessionStorage.removeItem("archivedpatientlimit");
            sessionStorage.removeItem("archivedpatientoffset");
            sessionStorage.removeItem("sortArchivedPatientParams");
            sessionStorage.removeItem("sortByArchivedPatientParams");
            sessionStorage.removeItem("sortOrderArchivedPatientParams");
            sessionStorage.removeItem("sortDeptParams");
            sessionStorage.removeItem("flagscurrentPage");
            sessionStorage.removeItem("flagslimit");
            sessionStorage.removeItem("flagsoffset");
            sessionStorage.removeItem("sortByFlag");
            sessionStorage.removeItem("sortOrderFlag");
            sessionStorage.removeItem("sortFlagParams");
            sessionStorage.removeItem("orgId");
            sessionStorage.removeItem("orgName");
            sessionStorage.removeItem("archivedorgId");
            sessionStorage.removeItem("archivedorgName");
            sessionStorage.removeItem("archiveddischargepageNumber");
            sessionStorage.removeItem("archiveddischargepageSize");
            sessionStorage.removeItem("archiveddischargesortBy");
            sessionStorage.removeItem("archiveddischargesortOrder");
            sessionStorage.removeItem("sortBy");
            sessionStorage.removeItem("sortOrder");
            sessionStorage.removeItem("employeeOrgId");
            sessionStorage.removeItem("employeeOrgName");
            sessionStorage.removeItem("managerOrgId");
            sessionStorage.removeItem("managerOrgName");
            sessionStorage.removeItem("adminOrgId");
            sessionStorage.removeItem("adminOrgName");
            sessionStorage.removeItem("dischargesOrgId");
            sessionStorage.removeItem("dischargesOrgName");
            // this._authenticationService.logout();
            this.router.navigate([""]);
          } else {
            this.router.navigate([""]);
          }
        }
        const error = err.error.message || err.statusText;
        // console.error(err);
        // this.logService.logError(`Error at line 13(error.interceptor.ts): ${error}`);
        return throwError(error);
      })
    );
  }
}
