<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown">
    <i class="ficon" data-feather="bell"></i>
    <span class="badge badge-pill badge-danger badge-up">{{unreadCount > 9 ? '9+' : unreadCount}}</span>
  </a>
  <ul ngbDropdownMenu aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right">
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <div class="badge badge-pill badge-light-primary">
          {{ unreadCount }} New
        </div>
      </div>
    </li>
    <li class="scrollable-container media-list" [perfectScrollbar]>
      <a class="d-flex" href="javascript:void(0)" *ngFor="let message of notifications; trackBy: trackByFunction"
        (click)="message.isRead ? null : readNotification(message.id)">
        <div class="media d-flex align-items-start">
          <div class="media-left">
            <div class="avatar-container mr-1">
              <span class="avatar">
                <div *ngIf="message.avatar">
                  <img class="rounded-circle" [src]="message.avatar" height="38" width="38" alt="datatable-avatar" />
                  <span class="avatar-status-online"></span>
                </div>
              </span>
              <span class="avatar">
                <div *ngIf="!message.avatar" class="rounded-square avatar-default-bg-color">
                  <span class="initials avatar-default-color">
                    {{ message.firstName.charAt(0) }}{{ message.lastName.charAt(0) }}
                  </span>
                </div>
              </span>
            </div>
          </div>
          <div class="media-body">
            <p class="media-heading" [innerHTML]="message.title"></p>
            <!-- <small class="notification-text">{{ message.created | date:'MM-dd-yyyy':'UTC' }} {{ message.created |
              date:'HH:mm':'UTC' }}</small> -->
            <small class="notification-text">{{ getTimeAgo(message.created) }}</small>
          </div>
          <span class="badge badge-pill badge-{{ message.isRead ? 'success' : 'danger' }}">{{ message.isRead ? '' :
            'Unread' }}</span>
        </div>
      </a>
    </li>
    <li class="dropdown-menu-footer" *ngIf="totalResults !== 0">
      <a class="btn btn-primary btn-block" href="javascript:void(0)" [routerLink]="['/pages/notifications']">View all
        notifications</a>
    </li>
    <li class="dropdown-menu-footer" *ngIf="totalResults === 0">
      <a class="btn btn-primary btn-block" href="javascript:void(0)">No notifications</a>
    </li>
  </ul>
</li>