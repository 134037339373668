<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/dashboard']">
        <span class="brand-logo mt-1">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="26" height="26" />
        </span>
        <h2 class="brand-text mb-0 mt-1">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content"
  [ngClass]="this._router.url === '/dashboard/facility'? 'stat-page-navbar' : ''">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig?.layout?.menu?.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->
    <!-- Toggle skin -->
    <li class="nav-item d-none d-lg-block">
      <a type="button" class="nav-link nav-link-style btn" [routerLink]="['/dashboard']">
        <span class="ficon font-medium-5 feather icon-home"></span>
      </a>
    </li>
    <li class="nav-item d-none d-lg-block" *ngIf="isSuperOrManager()">
      <a type="button" class="nav-link nav-link-style btn" [routerLink]="['/pages/tasks']">
        <span class="ficon font-medium-5 feather icon-check-square"></span>
      </a>
    </li>
  </div>
  
  <ul class="nav navbar-nav align-items-center ml-auto">
    
    <!-- Notification -->
    <app-navbar-notification *ngIf="isSuperOrManager()"></app-navbar-notification>
    <!--/ Notification -->
    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
        id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
        <div class="user-nav d-sm-flex d-none">
          <span class="user-name font-weight-bolder">{{firstName}} {{lastName}}</span><span class="user-status">
            {{currentUser?.role === "Super" ? currentUser?.role : currentUser?.organizationName + " " +
            currentUser?.role}}
          </span>
        </div>
        <core-avatar [user]="currentUser"></core-avatar>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <a ngbDropdownItem [routerLink]="['/pages/profile']"><span [data-feather]="'user'" [class]="'mr-50'"></span>
          Profile</a>
        <a ngbDropdownItem [routerLink]="['/pages/tasks']" *ngIf="isSuperOrManager()"><span [data-feather]="'check-square'"
            [class]="'mr-50'"></span> Task</a>
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>